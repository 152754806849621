<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dealer" label="星级" show-overflow-tooltip></el-table-column>
      <el-table-column prop="month_sale" label="当月业绩" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template #header>
          <y_choose_member @change="chooseNew">
            <el-button >添加经销商</el-button>
          </y_choose_member>
        </template>
        <template v-slot="s">
          <el-button @click="remove(s.row)" type="danger">移出</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

import y_choose_member from "@/components/shop/y_choose_member";
export default {
  components:{y_choose_member},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{

      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    remove(e){
      this.$u.api.shop.plugin.dealer.userSetLevel({uid:e.id,level:0}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    chooseNew(e){
      this.$u.api.shop.plugin.dealer.userSetLevel({uid:e.id,level:1}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.dealer.userSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>